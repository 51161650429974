import React, { useMemo, useState } from "react";
import { scaleTime, scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { curveBasis } from "@visx/curve";
import { ParentSize } from "@visx/responsive";
import { localPoint } from "@visx/event";
import { useTooltip, TooltipWithBounds, Tooltip } from "@visx/tooltip";
import {
  FaceSmileIcon,
  FaceFrownIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { bisector } from "@visx/vendor/d3-array";
import { Line } from "@visx/shape";

const isDefined = (val) => val !== undefined;
const allValuesNotUndefined = (d) => d.every(isDefined);

const xAccessor = (d) => new Date(d?.[0] ?? Date.now());
const yAccessor = (d) => d?.[1] ?? 0;
const sampleUrlAccessor = (d) => d?.[2] ?? 0;

const bisectDate = bisector((d) => xAccessor(d)).left;

const sentimentAxisLabels = (value, x, y) => {
  const props = { x, y, height: 20, width: 20, className: "text-slate-700" };
  switch (value.toString()) {
    case "−1.0":
      return <FaceFrownIcon {...props} />;
    case "1.0":
      return <FaceSmileIcon {...props} />;
    case "0.0":
      return <UserIcon {...props} />;
    default:
      return "";
  }
};

// TODO: Make this configurable in Ruby code.
const colorFromValue = (value) => {
  if (value > 0.65) return "#14532d";
  if (value > 0.3) return "#15803d";
  if (value < -0.3) return "#ef4444";
  if (value < -0.65) return "#991b1b";
  return "#475569";
};

const DateTooltip = ({ value, x, y }) => {
  return (
    <Tooltip
      key={Math.random()}
      top={y - 20}
      left={x - 10}
      className={"absolute bg-white border -translate-x-1/2 whitespace-nowrap"}
    >
      <div className="text-sm text-slate-600">{value}</div>
    </Tooltip>
  );
};

const ValueTooltip = ({ value, x, y }) => {
  return (
    <TooltipWithBounds
      key={Math.random()}
      top={y}
      left={x}
      className={"absolute bg-white border p-2"}
    >
      <span className="text-slate-600 text-sm">{value}</span>
    </TooltipWithBounds>
  );
};

const asResizableGraph =
  (Component) =>
  ({ data, samples }) => {
    const processedData = useMemo(
      () => data.filter(allValuesNotUndefined),
      [data],
    );
    const processedSamples = useMemo(
      () => samples.filter(allValuesNotUndefined),
      [samples],
    );

    return (
      <ParentSize debounceTime={10}>
        {({ width, height }) => {
          const defaultMargin = { top: 40, right: 40, bottom: 30, left: 40 };

          const xScale = scaleTime({
            range: [defaultMargin.left, width - defaultMargin.right],
            domain: [
              Math.min(...processedData.map(xAccessor)),
              Math.max(...processedData.map(xAccessor)),
            ],
          });
          const yScale = scaleLinear({
            range: [height - defaultMargin.bottom, defaultMargin.top],
            domain: [-1, 1],
          });

          return (
            <Component
              width={width}
              height={height}
              margin={defaultMargin}
              xScale={xScale}
              yScale={yScale}
              data={processedData}
              samples={processedSamples}
            />
          );
        }}
      </ParentSize>
    );
  };

const formatDate = (date: string) => {
  if (date.includes("T")) {
    return new Date(date).toLocaleString();
  }
  return date;
};

const Sample = ({ sample, index, x, y }) => {
  const [radius, setRadius] = useState(3);

  return (
    <a
      key={`sample-${index}`}
      href={sampleUrlAccessor(sample)}
      target={"_blank"}
      onMouseOver={(e) => {
        setRadius(10);
      }}
      onMouseOut={(e) => {
        setRadius(3);
      }}
    >
      <circle cx={x} cy={y} r={10} fill={"transparent"}></circle>
      <circle
        cx={x}
        cy={y}
        r={radius}
        opacity={0.8}
        fill={colorFromValue(yAccessor(sample))}
      ></circle>
    </a>
  );
};

const StaticLineGraph = ({
  data,
  samples,
  width,
  height,
  xScale,
  yScale,
  margin,
}) => {
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useTooltip();
  const handleMouseOver = (event) => {
    const coords = localPoint(event) || { x: 0, y: 0 };
    if (coords.x < margin.left || coords.x > width - margin.right) return;

    const x0 = xScale.invert(coords.x);
    const index = bisectDate(data, x0, 1);
    const d0 = data[index - 1];
    const d1 = data[index];
    let d = d0;

    // This works out which point we are closer to given our current x position.
    // Is it the point ahead of the mouse? Or the point behind the mouse?
    if (d1 && xAccessor(d1)) {
      d =
        x0.valueOf() - xAccessor(d0).valueOf() >
        xAccessor(d1).valueOf() - x0.valueOf()
          ? d1
          : d0;
    }

    showTooltip({
      tooltipData: d,
      tooltipLeft: xScale(xAccessor(d)),
      tooltipTop: yScale(yAccessor(d)),
    });
  };

  return (
    <div className={"relative"}>
      <svg
        width={width}
        height={height}
        onMouseMove={handleMouseOver}
        onMouseLeave={hideTooltip}
      >
        <Group>
          {samples.map((sample, i) => (
            <Sample
              sample={sample}
              index={i}
              x={xScale(xAccessor(sample))}
              y={yScale(yAccessor(sample))}
            />
          ))}
          <LinePath
            data={data}
            x={(d) => xScale(xAccessor(d))}
            y={(d) => yScale(yAccessor(d))}
            stroke="#1e40af"
            strokeWidth={2}
            curve={curveBasis}
          />
        </Group>
        <AxisLeft
          scale={yScale}
          tickValues={[-1, 0, 1]}
          left={margin.left}
          stroke="#cbd5e1"
          tickStroke={"#cbd5e1"}
          tickComponent={({ x, y, formattedValue }) =>
            sentimentAxisLabels(formattedValue, x - 25, y - 12)
          }
        />
        <AxisBottom
          scale={xScale}
          top={height - margin.bottom}
          tickLength={10}
          labelOffset={15}
          numTicks={2}
          hideZero={false}
          stroke="#cbd5e1"
          tickStroke={"#cbd5e1"}
          tickValues={xScale.domain()}
          tickFormat={(value) => value.toString().substring(0, 10)}
          tickLabelProps={() => ({
            fill: "#64748b",
            fontSize: 12,
            textAnchor: "middle",
            dy: "0.3em",
          })}
        />
        {tooltipData && (
          <Line
            from={{ x: tooltipLeft, y: margin.top }}
            to={{ x: tooltipLeft, y: height - margin.bottom }}
            stroke={"#1e40af"}
            strokeWidth={1}
            pointerEvents="none"
            strokeDasharray="5,2"
          />
        )}
      </svg>
      {tooltipData && (
        <React.Fragment>
          <DateTooltip
            value={formatDate(tooltipData?.[0])}
            x={tooltipLeft}
            y={height - margin.bottom}
          />
          <ValueTooltip
            value={yAccessor(tooltipData)}
            x={tooltipLeft}
            y={tooltipTop}
          />
        </React.Fragment>
      )}
    </div>
  );
};

const LineGraph = asResizableGraph(StaticLineGraph);
export default LineGraph;
