import ReactOnRails from "react-on-rails";

import LineGraph from "../bundles/./LineGraph/components/LineGraph";

ReactOnRails.setOptions({
  turbo: true,
});

// This is how react_on_rails can see the LineGraph in the browser.
ReactOnRails.register({
  LineGraph,
});
